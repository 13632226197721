<template>
  <LiefengContent>
    <template v-slot:title>防疫填报统计排行榜</template>
     <template v-slot:toolsbarLeft>
        <Dropdown style="margin-right:50px;">
        <Button type="primary">
            {{selectaArea.name}}
            <Icon type="ios-arrow-down"></Icon>
        </Button>
        <DropdownMenu slot="list">
            <DropdownItem> <div style="width:100%;height:100%;" @click="()=>{getArea({name:'各区排行榜',value:3})}">各区排行榜</div></DropdownItem>
            <DropdownItem> <div style="width:100%;height:100%;"  @click="()=>{getArea({name:'各街道排行榜',value:2})}">各街道排行榜</div></DropdownItem>
            <DropdownItem> <div style="width:100%;height:100%;"  @click="()=>{getArea({name:'各社区排行榜',value:1})}">各社区排行榜</div></DropdownItem>
        </DropdownMenu>
    </Dropdown>
     <!-- <div class="tooldiv">数据更新至2021.02.03 14:30</div>  -->
    </template>
    <template v-slot:toolsbarRight>
     <div class="tooldiv">居民防疫自报：</div>
    <Select v-model="keyword" filterable  placeholder="全部">
    <Option value="" selected>全部</Option>
    <Option :value="item.id" v-for="item in projectList" :key="item.id">{{item.title}}</Option>
    </Select>
      <Button type="info" icon="ios-search" @click="()=>{hadlePageSize({pageSize:pageSize,page:1})}"
                  >查询</Button>
     <!-- <Button type="default" icon="md-download" 
                  >导出</Button> -->
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :tableData="tableData"
        :talbeColumns="talbeColumns"
        :loading="loading"
        :fixTable="true"
        :pageSize="pageSize"
        :total="total"
        :pagesizeOpts="[20, 30, 50, 100]"
        :curPage="curPage"
        @hadlePageSize="hadlePageSize"
      ></LiefengTable>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/statisticsvirus19ranking')
import LiefengContent from "@/components/LiefengContent";
import LiefengTable from "@/components/LiefengTable";
export default {
  data() {
    return {
      projectCodes:null,
      projectList:[],
      selectaArea:{name:'各区排行榜',value:3},
      talbeColumns: [
        {
          title: "区",
          key: "zone",
          align: "center",
          minWidth: 140,
        },
        {
          title: "街道",
          key: "street",
          align: "center",
          minWidth: 180,
        },
        {
          title: "社区",
          key: "orgName",
          align: "center",
          minWidth: 140,
        },
        {
          title: "填报人数",
          key: "completeNum",
          align: "center",
          minWidth: 160,
         
        },
        {
          title: "排行",
          key: "rank",
          align: "center",
          minWidth: 140,
        },
      ],
      talbeColumns: [],
      tableData:[],
      keyword:"",
      loading: false,
      pageSize: 30,
      total: 0,
      curPage: 1,
    };
  },
  methods: {
      getArea(param) {
        this.selectaArea=param;
         this.hadlePageSize({
                pageSize: this.pageSize,
                page: 1,
              });
      },
       
       hadlePageSize(obj) {
         var busiIdList="";
      if(this.$core.ifNULLtoDF(this.keyword,null)==null){
        this.projectList.map(item=>{
          if(busiIdList!="") busiIdList+=","
          busiIdList+=item.id;
        });
      }else{
        busiIdList=this.keyword;
      }
      switch(this.selectaArea.value){
        case 1:
          this.talbeColumns= [
        {
          title: "区",
          key: "zone",
          align: "center",
          minWidth: 140,
        },
        {
          title: "街道",
          key: "street",
          align: "center",
          minWidth: 180,
        },
        {
          title: "社区",
          key: "orgName",
          align: "center",
          minWidth: 140,
        },
        {
          title: "填报人数",
          key: "completeNum",
          align: "center",
          minWidth: 160,
         
        },
        {
          title: "排行",
          key: "rank",
          align: "center",
          minWidth: 140,
        },
      ];
          break;
        case 2:
           this.talbeColumns= [
        {
          title: "区",
          key: "zone",
          align: "center",
          minWidth: 140,
        },
        {
          title: "街道",
          key: "street",
          align: "center",
          minWidth: 180,
        },
        {
          title: "填报人数",
          key: "completeNum",
          align: "center",
          minWidth: 160,
         
        },
        {
          title: "排行",
          key: "rank",
          align: "center",
          minWidth: 140,
        },
      ];
          break;
        case 3:
           this.talbeColumns= [
        {
          title: "区",
          key: "zone",
          align: "center",
          minWidth: 140,
        },
        {
          title: "填报人数",
          key: "completeNum",
          align: "center",
          minWidth: 160,
         
        },
        {
          title: "排行",
          key: "rank",
          align: "center",
          minWidth: 140,
        },
      ];
          break;
      }
      this.loading = true;
      this.$get("/old/api/pc/dynamicform/getRankOfSelfComplete", {
        busiIdList: busiIdList,
        area: this.selectaArea.value,
        custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
        orgCode:parent.vue.loginInfo.userinfo.orgCode,
      })
        .then((res) => {
          if (res.code == 200 && res.dataList) {
            this.tableData = res.dataList;
            this.pageSize = res.pageSize;
            this.total = res.total;
            this.curPage = res.curPage;
          } else {
            this.tableData =[];
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          this.$Message.error({
            background: true,
            content: "获取数据失败",
          });
        });
    },
  },
  created() {
    this.projectCodes=this.$core.ifNULLtoDF(this.$core.getUrlParam("projectcodes"),null);
    if(this.projectCodes!=null){
      
      this.$get("/datamsg/api/pc/information/v2/selectLabelByCodeList", {
        codeList: this.projectCodes,
      })
        .then((res) => {
          if (res.code == 200 && res.dataList) {
            this.projectList= res.dataList;
              this.hadlePageSize({
                pageSize: this.pageSize,
                page: this.curPage,
              });
          }
              })
        .catch((err) => {
        
        });
    }
  
  },
  components: {
    LiefengContent,
    LiefengTable,
  },
};
</script>
    
<style scoped lang='less'>
</style>